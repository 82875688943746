.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

@font-face {
  font-family: 'Bogart';
  src: url('./fonts/Bogart-Regular-trial.ttf') format('truetype');
  /* Add additional font formats (e.g., woff, woff2) for cross-browser compatibility */
}

@font-face {
  font-family: 'Montserrat';
  src: url('./fonts/Montserrat-Regular.ttf') format('truetype');
  /* Add additional font formats (e.g., woff, woff2) for cross-browser compatibility */
}

h1 {
  font-family: 'Bogart', 'Times New Roman', Times, serif;
  font-size: 56px;
  margin: 0px;
  padding: 0px;
  line-height: 1.2;
}


h2 {
  font-family: 'Montserrat', 'Times New Roman', Times, serif;
  font-size: 24px;
  margin: 0px;
}

h3 {
  font-family: 'Montserrat', 'Times New Roman', Times, serif;
  font-size: 16px;
  font-weight: 300;
}

h4 {
  font-family: 'Bogart', 'Times New Roman', Times, serif;
  font-size: 36px;
  margin: 0px;
  padding: 0px;
  line-height: 1.2;
}

ul {
  list-style-type: circle;
  margin-top: 5px
}

li {
  margin-bottom: 5px;
}

.hover-effect:hover {
  filter: grayscale(100%);
  transition: filter 0.5s;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}

@keyframes backgroundsun {
  0%   {background-color:#001F54}
  25%  {background-color:#334c76}
  50%  {background-color:#667998}
  75%  {background-color:#99a5bb}
  100% {background-color:#EEE7EE}
}

@keyframes move-in {
  from {
    top: 300px;
    left: 0px;
  }
  to {
    top: 150px;
    left: 175px;
  }
}

@keyframes move-out {
  from {
    top: 150px;
    left: 175px;
  }
  to {
    top: 200px;
    left: 1500px;
  }
}

@keyframes bounce {
  0%, 20%, 50%, 80%, 100% {
    transform: translateY(0);
  }
  40% {
    transform: translateY(-10px);
  }
  60% {
    transform: translateY(-5px);
  }
}